import styled from "styled-components";

export const StyledButton = styled("button")<{ color?: string }>`
  background: ${(p) => p.color || "#4CAF50"}; /* Primary Green */
  color: ${(p) => (p.color ? "#FFFFFF" : "#fff")}; /* White text */
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #E0E0E0; /* Light Gray border */
  border-radius: 4px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid #FF9800; /* Orange for hover/active/focus */
    background-color: #FF9800; /* Orange for hover/active/focus */
  }
`;
