const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/about", "/about-us"],
    exact: true,
    component: "AboutUs",
  },
  {
    path: ["/blog", "/blogs"],
    exact: true,
    component: "Blogs",
  },
  {
    path: ["/dayana"],
    exact: true,
    component: "Dayana",
  },
  {
    path: "/services", // Add this route
    exact: true,
    component: "Services",
  },
];

export default routes;
