import { useState } from "react";
import { SvgIconProps } from "../types";

export const SvgIcon = ({ src, width, height }: SvgIconProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const brandOrange = "#FFA500"; // Replace with your exact brand orange color
  const lineThickness = "4px"; // Increase thickness here

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        padding: "5px",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={`/img/svg/${src}`}
        alt={src}
        width={width}
        height={height}
        style={{ display: "block" }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isHovered ? "50%" : "0",
            height: lineThickness,
            backgroundColor: brandOrange,
            transition: "width 0.3s ease",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: isHovered ? "50%" : "0",
            height: lineThickness,
            backgroundColor: brandOrange,
            transition: "width 0.3s ease",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: isHovered ? "50%" : "0",
            height: lineThickness,
            backgroundColor: brandOrange,
            transition: "width 0.3s ease 0.6s",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: isHovered ? "50%" : "0",
            height: lineThickness,
            backgroundColor: brandOrange,
            transition: "width 0.3s ease 0.6s",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: lineThickness,
            height: isHovered ? "50%" : "0",
            backgroundColor: brandOrange,
            transition: "height 0.3s ease 0.3s",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: lineThickness,
            height: isHovered ? "50%" : "0",
            backgroundColor: brandOrange,
            transition: "height 0.3s ease 0.3s",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: lineThickness,
            height: isHovered ? "50%" : "0",
            backgroundColor: brandOrange,
            transition: "height 0.3s ease 0.9s",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: lineThickness,
            height: isHovered ? "50%" : "0",
            backgroundColor: brandOrange,
            transition: "height 0.3s ease 0.9s",
          }}
        />
      </div>
    </div>
  );
};
