import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background: #f9f9f9; /* Updated to a lighter gray */
  padding: 2.5rem 0;

  @media screen and (max-width: 768px) {
    padding: 2rem 0;
  }
`;

export const Title = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #4caf50; /* Primary green color for consistency */

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    font-size: 18px; /* Adjust font size for smaller screens */
  }
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;
  color: #333; /* Default link color set to dark gray */

  &:hover,
  &:active,
  &:focus {
    color: #4caf50; /* Green on hover/active/focus for consistency */
  }
`;

export const Extra = styled("section")`
  background: #f9f9f9; /* Match footer background */
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;

  @media screen and (max-width: 768px) {
    padding-bottom: 1.5rem;
  }
`;

export const LogoContainer = styled("div")`
  display: flex;
  position: relative;
`;

export const Para = styled("div")`
  color: #666; /* Medium gray for softer text */
  font-size: 14px;
  width: 70%;

  @media screen and (max-width: 768px) {
    width: 100%; /* Adjust width for smaller screens */
  }
`;

export const Large = styled(Link)`
  font-size: 16px;
  color: #333; /* Dark gray for consistency */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  max-width: max-content;

  &:hover {
    color: #ff9800; /* Orange on hover */
    text-underline-position: under;
    text-decoration: #ff9800 wavy underline;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

export const Chat = styled("p")`
  color: #333; /* Dark gray for consistency */
  max-width: fit-content;
  border-bottom: 1px solid #333; /* Matching the text color */
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-bottom: 1px solid #ff9800; /* Orange on hover */
    color: #ff9800;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

export const Empty = styled("div")`
  position: relative;
  height: 53px;
`;

export const FooterContainer = styled("div")`
  max-width: 510px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  transition: all 0.1s ease-in-out;

  a {
    &:hover,
    &:active,
    &:focus {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 769px) {
    width: auto;
    flex-wrap: wrap; /* Wrap social links on smaller screens */
    justify-content: center; /* Center the social links */
    
    a:not(:last-child) {
      margin-right: 10px;
    }
  }

  div {
    cursor: pointer;
    margin-right: 15px;
    width: 25px;
    height: 25px;

    &:hover {
      fill: #ff9800; /* Orange fill on hover */
    }
  }
`;

export const Language = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #4caf50; /* Primary green color */

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    font-size: 18px; /* Adjust font size for smaller screens */
  }
`;

export const Label = styled("label")`
  font-size: 22px;
  text-transform: capitalize;
  color: #4caf50; /* Primary green color */
  display: block;
  margin-bottom: 2rem;
  font-family: "Motiva Sans Bold", serif;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    margin-bottom: 1rem;
    font-size: 18px; /* Adjust font size for smaller screens */
  }
`;

export const LanguageSwitch = styled("div")`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 85px;

  @media screen and (max-width: 768px) {
    justify-content: center; /* Center language switches on smaller screens */
    width: 100%; /* Adjust width on smaller screens */
  }
`;
